<template src="./CardDownload.html"></template>
<script>
import jsPDF from "jspdf";

export default {
  components: {
    jsPDF,
  },
  data() {
    return {
      cards: [],
      isDasa: false,
      ready: false,
    }
  },
  methods: {
    downloadCard() {
      const Options = {
        orientation: "portrait",
        unit: "px",
        format: [1485, 2108],
      };

      const doc = new jsPDF(Options);

      const margin = {
        top: 40,
        bottom: 60,
        left: 40,
        with: 522,
      };

      const pdfjs = document.querySelector("#container-cards");

      if (pdfjs !== null) {
        const printOptions = {
          margin: margin.top,
          callback: (doc) => {
            doc.save("cards-download.pdf");
            setTimeout(() => {
              window.close();
            });
          },
        };

        doc.html(pdfjs, printOptions);
      }
    },
    formatName(fullName) {
      const fullNameSplitted = fullName.split(" ");
      const firstName = fullNameSplitted[0];
      const lastName = fullNameSplitted[fullNameSplitted.length - 1];

      if (fullNameSplitted.length > 2) {
        let surname = "";
        for (var i = 1; i < (fullNameSplitted.length - 1); i++) {
          surname += " " + fullNameSplitted[i].charAt(0).toUpperCase() + ". ";
        }

        return firstName.toUpperCase() + " " + surname + " " + lastName.toUpperCase();
      }

      return firstName.toUpperCase() + " " + lastName.toUpperCase();
    },

    formatCardNumber(cardNumber) {
      return cardNumber.replace(/^(\d{4})\s?\s?(\d{4})\s?\s?(\d{4})\s?\s?(\d{4})$/, "$1 $2 $3 $4");
    },

    init() {
      this.customerData = this.$session.get("customerData");
      this.axios
        .get(
          this.$store.state.filooServer +
          "patient/patient-dependents/" +
          this.customerData.patientId,
          {
            headers: { Authorization: this.$session.get("bearer") },
          }
        )
        .then(({ status, data: patients }) => {
          this.isDasa = patients.some(value => value.isDasa === true);
          if (status == 200 && patients.length > 0) {
            for (const p in patients) {
              patients[p].patientCardNumber = this.formatCardNumber(patients[p].patientCardNumber);
              patients[p].name = this.formatName(patients[p].name);
              this.cards.push(patients[p]);
            }
            this.ready = true;
            this.downloadCard();
          }
        }).catch(function (error) {
          console.log(error);
        });
    },
  },

  mounted: function () {
    this.init();
  },
};
</script>

<style lang="scss" src="./CardDownload.scss" scoped="true"></style>
